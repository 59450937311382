// Breadcrumb
//--------------------

.breadcrumb {
  margin: 0;
  padding: 22px 15px;
  background: transparent;
  /*@media (min-width: $screen-xs-min) {
    padding: 22px 15px;
  }*/
  li a {
    color: $white;
    &:hover {
      color: $color-mandy;
    }
  }
  li.active {
    color: $white;
  }
  > li + li:before {
    content: '';
    height: 21px;
    margin-left: 3px;
    border-left: 1px solid $gray-base;
  }
}