//
// Visibility Responsive
// --------------------------------------------------

@include make-visible(block);
@include make-visible(inline-block);
@include make-visible(inline);
@include make-visible(flex);
@include make-hidden();

@each $alias, $resolution in (xs : ($screen-xs-min, $screen-xs-max), sm : ($screen-sm-min, $screen-sm-max), md : ($screen-md-min, $screen-md-max), lg : ($screen-lg-min, $screen-lg-max), xl : $screen-xl-min){
  @if (length($resolution) == 2) {
    @media (min-width: nth($resolution, 1)) and (max-width: nth($resolution, 2)) {
      @include make-visible(block, $alias);
      @include make-visible(inline-block, $alias);
      @include make-visible(inline, $alias);
      @include make-visible(flex, $alias);
      @include make-hidden($alias);
    }
  }
  @else {
    @media (min-width: nth($resolution, 1)) {
      @include make-visible(block, $alias);
      @include make-visible(inline-block, $alias);
      @include make-visible(inline, $alias);
      @include make-visible(flex, $alias);
      @include make-hidden($alias);
    }
  }
}