//
// Media Custom
// --------------------------------------------------

.media-variant-1{
  .media-left,
  .media-right,
  .media-body {
    display: block;
    @media (min-width: 600px){
      display: table-cell;
      vertical-align: top;
    }
  }
  .media-body{ width: auto; }
  .media-left{
    padding-bottom: 20px;
    padding-right: 0;
    @media (min-width: 600px) {
      min-width: 200px;
      padding-right: 20px;
      padding-bottom: 0;
    }
  }
  .media-right{
    padding-top: 20px;
    padding-left: 0;
    @media (min-width: 600px) {
      min-width: 200px;
      padding-left: 20px;
      padding-top: 0;
    }
  }
}

.media-variant-2{
  .media-left,
  .media-right,
  .media-body {
    display: block;
    @media (min-width: 600px){
      display: table-cell;
      vertical-align: middle;
    }
  }
  .media-body{ width: 100%; }
  .media-left{
    padding-bottom: 20px;
    padding-right: 0;
    @media (min-width: 600px) {
      padding-right: 20px;
      padding-bottom: 0;
    }
  }
  .media-right{
    padding-top: 20px;
    padding-left: 0;
    @media (min-width: 600px) {
      padding-left: 20px;
      padding-top: 0;
    }
  }
  .post-meta{
    display: inline-block;
  }
}

.media-variant-3{
  .media-left,
  .media-right,
  .media-body {
    display: block;
    img {
      height: auto;
    }
    @media (min-width: 768px){
      display: table-cell;
      vertical-align: top;
      img{
        width: auto;
      }
    }
  }
  .media-body{ width: auto; }
  .media-left{
    padding-bottom: 20px;
    padding-right: 0;
    @media (min-width: 768px) {
      min-width: 50%;
      padding-right: 20px;
      padding-bottom: 0;
    }
  }
  .media-right{
    padding-top: 20px;
    padding-left: 0;
    @media (min-width: 768px) {
      min-width: 50%;
      padding-left: 20px;
      padding-top: 0;
    }
  }
}