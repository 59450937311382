@media (max-width: $grid-float-breakpoint-max) {
	.rd-navbar-fixed {
		.rd-navbar-panel {
			flex-direction: column-reverse;
			height: auto;
			margin-top: 0;
			z-index: auto;
		}

		.rd-navbar-brand {
			position: static;
			top: auto;
			left: auto;
			right: auto;
			padding: 0;

			.brand-name {
				display: block;
			}
		}

		.rd-navbar-nav-wrap {
			z-index: 1000;
			top: 0;
			width: calc(100vw - 60px);
			position: fixed;
			bottom: 0;
		}

		.rd-navbar-toggle {
			float: none;
			position: absolute;
			top: 0;
			right: 0;
		}

		.rd-navbar-nav {
			width: 100%;

			li > a,
			li > a:focus {
				color: #fff !important;
			}
		}
	}

	.mobile .rd-navbar-top-panel .block-right .fejlec-usermenu.active {
		display: flex;
		flex-direction: column;
		top: 100%;
		transform: none;
		left: 0;
		background: #2b2b2b;
		margin: 0;

		>li {
			padding: 20px;
			border-top: 1px solid #404040;
		}

		>li+li {
			border-left: 0;
		}
	}

	.rd-navbar-search.active .rd-navbar-search-form {
		background: #2b2b2b;
		height: auto;
		margin: 0;
		width: 100vw !important;
		left: 0;

		.form-group {
			position: static;
			height: auto;
		}

		.btn {
			width: 100%;
		}
	}

	.swiper-container {
		height: 260px;
		margin-bottom: 2em;
	}
	
	.swiper-slide-caption h2 {
		font-size: 20px;
		text-align: center;
		margin-bottom: 24px;
	}

	.swiper-container-horizontal > .swiper-pagination {
		left: 50%;
		right: auto;
		bottom: 5px;
		transform: translate(-50%, 0);
	}

	.fooldal-kiemelt-hir {
		.blog-post-variant-3 .post-img {
			display: block;
			width: 100%;
		}

		.blog-post-variant-3 .post-body .post-body-inside {
			position: static;
			transform: none;
		}

		.blog-post-variant-3 .post-body .post-title {
			width: auto;
			display: block;
		}

		.blog-post-variant-3 .btn {
			display: none;
		}
	}
}