// Wrap custom
//---------------------------
@media (max-width: 479px) {
  main{
    .container{
      padding: 0;
       h4 { padding: 0 15px; }
    }
    section{
      > h4 { padding: 0 15px; }
    }
  }

  .sidebar-module{
    > h4 { padding: 0 15px; }
  }
}

.wrap{
  background-color: $gray-dark;
  padding: 24px 30px 30px;
  @media (min-width: $screen-sm-min) {

    p{
      padding-right: 25px;
    }
  }
}

.wrap-1{
  @extend .wrap;
  padding: 30px;
  .media-body{
    p{
      padding-right: 0;
    }
  }
}

.border-mandy{ border-top:3px solid $color-mandy; }
.border-tultip{ border-top:3px solid $color-tulip-tree; }
.border-green{ border-top:3px solid $color-ufo-green; }
.border-primary{ border-top:3px solid $brand-primary; }
.border-crusta{ border-top:3px solid $color-crusta; }


//time
//------------------

// Helpers
//--------------------------
.block-inline{
  display: inline-block;
}
.full-width{
  width: 100%;
}
.relative{
  position: relative;
}

.offset-p{
  margin-top: 7px;
}
//Main layout
//--------------------------

@media (max-width: 767px) {
  .row {
    * + .col-xs-12 {
      margin-top: 40px;
    }
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .row {
    .col-sm-6:nth-child(n+3) {
      margin-top: 40px;
    }
  }
}
.row.row-no-gutter {
  margin-left: 0;
  margin-right: 0;

  > [class*='col'] {
    padding-left: 0;
    padding-right: 0;
  }

  > * + [class*='col'] {
    margin-top: 0;
  }
}


* + p { margin-top: 7px; }
* + h1,
* + h2,
* + h3,
* + h4,
* + h5,
* + h6,
* + .h1,
* + .h2,
* + .h3,
* + .h4,
* + .h5,
* + .h6{ margin-top: 18px; }
* + .wrap{ margin-top: 15px; }
* + .img-thumbnail-variant-1 { margin: 20px 0; }
* + .img-thumbnail-variant-2 { margin-top: 20px; }
* + .sidebar-module{ margin-top: 48px;}
* + .post-title{ margin-top: 15px; }
* + .rd-navbar-search { margin-top: 23px; }
* + address { margin-top: 18px; }
* + .list-unordered-variant-1,
* + .list-index{ margin-top: 18px;}
* + .twitter-timeline{ margin-top: 23px !important; }
* + .fb-page-responsive{ margin-top: 23px  }

h4 + .tag-group { margin-top: 20px; }
h4 + .list-variant-1 { margin-top: 10px; }
h5 + p { margin-top: 19px; }
h4 + p { margin-top: 7px; }
p + .list-inline-variant-1{ margin-top: 26px; }
.wrap + h4,
.wrap-1 + h4 { margin-top: 30px;}
.wrap + .wrap{ margin-top: 0;}
p + .btn { margin-top: 20px; }
.quote1 + .post-meta { margin-top: 0; }
.post-meta + p { margin-top: 15px; }
.post-title + p { margin-top: 7px; }
.img-thumbnail-variant-2 + .post-title { margin-top: 25px; }
p + .quote-1 { margin-top: 18px; }
p + .img-thumbnail-variant-2,
.img-thumbnail-variant-2 + p { margin-top: 25px; }
p + .post-img,
p + .blog-post-variant-5,
p + .blog-post-variant-7 { margin-top: 25px;}
address + h5 { margin-top: 20px; }
h5 + .dl-horizontal-variant-1 { margin-top: 18px; }

h4 + *{ margin-top: 20px;}
h4 + .wrap,
h4 + .wrap-1{ margin-top: 20px;}
.post-title+.post-meta{ margin-top:20px; }
p + .post-meta{ margin-top: 20px;}
.post-title + iframe{
  margin-top: 20px;
}

/* Clearleft */

.clearleft-custom {
  @media (min-width: 768px) and (max-width: 991px) {
    [class*="col-"]:nth-child(odd) {
      clear: left;
    }
  }
}
.clearleft-custom-1 {
  @media (min-width: 768px) and (max-width: 1199px) {
    [class*="col-"]:nth-child(odd) {
      clear: left;
    }
  }
}
/*Images*/
img.img-width-auto {
  @media (min-width: $screen-lg-min) {
    height: 100%;
    width: auto;
  }

}

/*Flow offsets*/
.flow-offset-1{
  [class*="col-"]:nth-child(n+2){
    margin-top: 30px;
  }
  @media (min-width: $screen-sm-min) {
    [class*="col-"]:nth-child(n+2) {
      margin-top: 0;
    }
    [class*="col-"]:nth-child(n+3){
      margin-top: 30px;
    }
  }
}

/*Comment*/
.wrap-comment{

}
.wrap-reply{
  margin-top: 25px;
  @media (min-width: $screen-sm-min) {
    padding-left: 30px;
  }
}

/* Search */

.result-item{
 & + *{
   margin-top: 30px;
 }
}
.search_list{
  counter-reset: li;
  li {
    position: relative;
    padding-left: 30px;
    &:before {
      position: absolute;
      top: 3px;
      left: 0;
      content: counter(li, decimal-leading-zero) '.';
      counter-increment: li;
      margin-right: 6px;
      font-size: 16px;
      color: $brand-primary;
    }
  }
  .search_error{
    padding-top: 4px;
  }
  span.search{
    word-wrap: break-word;
  }
}
.rd-navbar-search-mod-1{
  position: relative;
  * + &{margin-top: 25px;}

  .btn {
    height: 50px;
    &:after{
      display: none;
    }
    @media (min-width: 600px) {
      position: absolute;
      top: 0;
      right: 0;
      margin-top: 0;
    }
  }
  $input-height: 50px;
  $input-font-weight: inherit;
  $input-font-size: 14px;
  $input-line-height: 34px;
  $input-color: $white;
  $input-background: $gray-dark;
  $input-padding: ($input-height - $input-line-height) / 2 112px ($input-height - $input-line-height) / 2 18px ;
  $input-border-color: $gray-darker;
  $input-border: 1px solid $input-border-color;

  input {
    display: block;
    width: 100%;
    height: $input-height;
    font-size: $input-font-size;
    padding: $input-padding;
    line-height: $input-line-height;
    color: $input-color;
    background: $input-background;
    border:$input-border;
    @include placeholder($white);

    &:focus,
    &:active,
    &:active:focus {
      outline: none;
    }
  }
  label{
    font-weight: 400;
  }
}

/*Terms-list*/
.terms-list {
  > dt + dd {
    margin-top: 20px;
  }

  > dd + dt {
    margin-top: 30px;
  }

  dt {
    margin: 0;
    font-family: $font-family-sec;
  }
}

.terms-list + .btn {
  margin-top: 30px;
}