.ui-to-top {
  background: $color-mandy;
}

.ui-to-top:hover {
  background: $white;
}

.ui-to-top:hover {
  &::before {
    color: $color-mandy;
  }
}

a {
  color: $color-mandy;
  &:hover, &:focus {
    color: $white;
  }
}

.link-mandy {
  color: $color-mandy;
  &:hover, &:focus {
    color: $white !important;
  }
}

.link-white {
  color: $white;
  &:hover, &:focus {
    color: $color-mandy !important;
  }
}

.rd-navbar-nav {
  display: flex !important;
  padding: 0 !important;

  li {
    margin: 0 !important;
    a {
      padding-left: 5px !important;
      padding-right: 5px !important;
      font-size: 13px !important;

      &:hover, &:focus {
        color: $color-mandy !important;

        &::before {
          background-color: $color-mandy !important;
        }
      }
    }

    &.kiemelt {
      background: rgba(red($brand-primary), green($brand-primary), blue($brand-primary), .7);
      > a {
        &:hover, &:focus {
          background: $white;
          &::before {
            background-color: $white !important;
          }
        }
      }

    }

    &.active, &.focus {
      > a {
        color: $color-mandy !important;

        &::before {
          background-color: $color-mandy !important;
        }
      }
    }
  }

  li.active.kiemelt {
    a {
      color: white !important;
    }
  }
}

.rd-navbar-dropdown li a {
  color: $white !important;
  &:hover, &:focus {
    color: $color-mandy !important;
  }
}

.width-full-margin {
  width: calc(100% - 30px);
  margin: 0 15px;
}

.width-half-margin {
  width: calc(50% - 30px);
  margin: 0 15px;

  @media (max-width: $screen-xs-max) {
    width: calc(100% - 30px);
  }
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-bottom-25 {
  margin-bottom: 25px;
}

.blog-post-variant-8 .tag {
  top: 0;
  left: 0;
}

.rd-navbar-search-toggle:before {
  content: "\E853";
}

.rd-navbar-search-toggle:before, .rd-navbar-search-toggle:after {
  color: $color-mandy;
}

.rd-navbar-static .rd-navbar-panel {
  padding: 10px 0 0 0;
}

.forum-lista {
  .felso-link {
    margin: 0 -15px;
    padding: 10px 15px;
  }

  .forum-elem {
    margin: 3px -15px;
    padding: 10px 30px;
    font-size: 17px;

    .disqus-comment-count {
      font-size: 14px;
    }
  }
}

.tv-musor {
  margin-bottom: 10px;
  .tv-img {
    width: 30%;
  }
  .tv-body {
    width: 70%;
    .tv-title {
      padding: 5px 10px;
      time {

      }
      h6 {
        margin: 5px 0;
      }
      p {
        margin: 0;
      }
    }
  }

  .tv-img-szeles {
    width: 15%;
  }
  .tv-body-szeles {
    width: 85%;
    display: flex;
    align-items: center;
    .tv-title {
      margin-left: 20px;
      padding: 5px 10px;
      time {

      }
      h6 {
        margin: 5px 0;
      }
      p {
        margin: 0;
      }
    }
  }
}

.rd-navbar-collapse-toggle span {
  background: $color-mandy;
}

.side-mecs-elem-nagy {
  margin-bottom: 15px;
  padding: 15px 15px;
  font-size: 15px;
  h4 {
    margin-bottom: 10px;
  }
}

.side-mecs-elem-kicsi {
  margin-bottom: 1px;
  padding: 10px 15px;
  font-size: 14px;
}

.manutd {
  color: $color-mandy;
}

.mecsgomb {
  margin: 10px 0;
}

.visszaszamlalo {
  padding: 10px 0 0 0;
  color: $color-mandy;
}

#elozomecsek {
  display: none;
}

.mecs-eredmeny {
  color: $color-mandy;
}

.szavazas-elem {
  margin-bottom: 10px;
  padding: 10px 15px;
  h3 {
    margin-bottom: 7px;
    font-size: 25px;
  }
}

.szavazas-opcio-lista {

}

.szavazas-opcio-elem {
  margin-bottom: 5px;
}

.szavazat-szam {
  color: $color-mandy;
  padding: 0 3px;
}

.rd-navbar-top-panel, .rd-navbar-top-panel .rd-navbar-top-panel-wrap {
  background: $gray-dark;
}

.rd-navbar-top-panel .rd-navbar-top-panel-wrap .block-right {
  z-index: 100;
}

.logged-form {
  padding: 10px 15px;
}

label {
  color: $white;
  font-weight: normal;
}

input, textarea, .form-control {
  &:focus {
    border-color: $color-mandy;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(red($color-mandy), green($color-mandy), blue($color-mandy), 0.6);
  }
}

.login-form {
  padding: 40px 15px 10px;
  .login-top-label {
    font-size: 15px;
  }
  .login-top-input {
    background-color: $body-bg !important;
    color: $white;
    margin-bottom: 10px;
  }
}

.pl-fordulo-lista {
	.pl-fordulo-elem {
		&.active {
			font-weight: bold;
		}
	}
}

.pl-fordulo {
  padding: 10px 15px;
}

.text-mandy {
  color: $color-mandy;
}

.fordulo-tablazat {
  tr {
    margin-bottom: 10px;
  }

  td {
    padding: 5px 5px;
    .fordulo-input {
      width: 40px;
      color: $color-mandy;
    }
  }
}

.fordulo-tablazat-sor {

}

.tv-stream {
  padding: 20px 15px 15px;
  margin-bottom: 10px;
  .felso-resz {
    font-size: 18px;
    margin-bottom: 5px;
  }
}

.szereples-tablazat {
  padding: 0 10px;
  thead {
    th {
      font-weight: normal;
      color: $white;
      font-size: 16px;
    }
  }

  tbody > tr:nth-of-type(odd) {
    background-color: #444;
  }
}

.szereples-nev {
  font-weight: 500;
  color: #fff;
}

.statisztika-valaszto-lista {
  padding: 10px 15px;
  margin-bottom: 15px;
  .statisztika-valaszto-elem {
    &.active {
      a {
        color: $white;
      }
    }
  }
}

.row-no-margin {
  margin: 0;
}

.jubotron-variant-1 {
  //background: rgba(red($body-bg), green($body-bg), blue($body-bg), 0.3);
  padding: 15px 20px 5px;
}

.swiper-slide-caption h2 {
  max-width: 100%;
  font-size: 32px;
  display: block;
}

.mecs-osszefoglalo {
  margin-top: 10px;
}

.mecs-osszefoglalo-link {
  color: $text-color;
  > span {
    font-size: 18px;
    padding-bottom: 10px;
	color: $color-mandy;
  }
}

.alap-padding {
  padding: 10px 15px;
}

.margint-top {
  margin-top: 15px;
}

.input-group-btn {
  padding-left: 10px;
  > a {
    padding-right: 10px;
  }
}

.utazas-elem {
  margin-bottom: 15px;
  .kit{
    margin-left: 2rem;
  }
}

.tag-group {
  > .tag {
    font-size: 13px;
    margin-top: 5px;
    margin-left: 5px;
  }
}

.cimke-resz {
  padding: 20px;
}

.swiper-container {
  background: transparent;
  height: 390px;
}

.swiper-slide-caption {
  @include gradient-vertical($start-color: rgba(0, 0, 0, 0), $end-color: rgba(0, 0, 0, .4), $start-percent: 40%, $end-percent: 100%);

  a {
    text-shadow: 0 0 20px rgba(0, 0, 0, .4);
    color: #fff;
  }

  .btn {
    margin-left: 0;
    margin-top: 15px;
	margin-right: 15px;
  	padding: 10px 15px;
  }
}

.swiper-container-horizontal > .swiper-pagination {
  left: auto;
  right: 32px;
  bottom: 32px;
}

.swiper-button-prev,
.swiper-button-next {
  display: none;
}

.swiper-pagination-bullet {
  border: 2px solid rgba(255, 255, 255, .5);
}

.blog-post-variant-3 .post-body .post-body-inside {
  padding: 15px 20px;
}

.blog-post-variant-3 .post-body .post-title time {
  font-size: 12px;
}

.blog-post-variant-8 {
  height: 100%;
}

.blog-post-variant-8 .tag {
  font-size: 12px;
}

.table > thead > tr > th, .table > thead > tr > td,
.table > tbody > tr > th, .table > tbody > tr > td,
.table > tfoot > tr > th, .table > tfoot > tr > td {
  border-top-color: rgba(255, 255, 255, .07);
}

.table > thead > tr > th {
  border-bottom: 1px solid rgba(255, 255, 255, .3);
}

.table-tabella {
  margin-bottom: 0;
  margin-top: 20px;
  
  .vonal {
    border-bottom: 2px solid #DA020E;
  }
}

.fooldal-kiemelt-hir {
  width: 100%;

  h3 {
    font-size: 20px !important;
  }

  time {
    display: block !important;
    margin: 10px 0;
  }

  .blog-post-variant-3 .post-body .post-title {
    width: 60%;
  }

  .blog-post-variant-3 .post-body .post-body-inside {
    transform: translateX(38%);
  }
}

.szoveges img,
.hir img {
  width: auto;
  max-width: 100%;
}

.szoveges .panel-group {
  .panel-default {
    border: none;
    border-bottom: 1px solid $brand-primary;
    background: transparent;
    margin-top: 10px;
    margin-bottom: 30px;
    padding-bottom: 20px;

    &:last-child {
      border-bottom-color: transparent;
    }

    .panel-heading {
      background: transparent;
      padding: 10px 0 15px;
      .panel-title {
        position: relative;
        color: $brand-primary;
        padding-bottom: 10px;
        font-weight: normal;
        font-size: 25px;

        &:hover, &:focus {
          color: $white;
        }

        svg {
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translate(0, -50%);
        }

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100px;
          height: 3px;
          background: $white;
        }
        &[aria-expanded=true] .blokk-caret {
          @include scaleY(-1);
        }
      }
    }

    .panel-body {
      padding: 10px 0;
      border: none;
    }
  }

}

.blokk-caret {
  @include transform-origin(50% 50%);
  @include transition(all .3s);
  @include scaleY(1);
}

.page-foot .copyright p {
  margin-left: 0;
  margin-bottom: 15px;
}

.footer-menu-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: space-between;
  .footer-menu-elem {
    padding: 5px 15px;
  }
}

.margin-top {
  margin-top: 20px;
}

.szoveg {
  ul {
    list-style: square;
    padding-left: 16px;
  }
}

.klub_regisztracio {
  display: none;
}

.reg_radio_label {
  text-align: center;
  padding: 25px 15px;
  background: white;
  color: $color-mandy;
  position: relative;

  span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.klub_regisztracio:hover + label.reg_radio_label {
  background: $color-mandy;
  color: white;
}

.klub_regisztracio:checked + label.reg_radio_label {
  background: $color-mandy;
  color: white;
}

.datetimepicker {
	color: #333;
}