/*
* @subsection   RD Calendar
*
*
* @author       Evgeniy Gusarov
* @see          https://ua.linkedin.com/pub/evgeniy-gusarov/8a/a40/54a
* @version      1.0.0
*/

@import "../_variables.scss";

.rd-calendar {
  @media (min-width: 640px) and (max-width: 991px) {
    margin-left: -20px;
  }
  &.show-events {

  }
}


// Today
.rdc-today_day{

}

.rdc-today_date{
  display: inline-block;
  color: $white;
}

.rdc-today_month{
  display: inline-block;
  color: $white;
}

.rdc-today_fullyear{

}


// Panel
.rdc-panel{
  text-align: center;
}

// Controls
.rdc-next, .rdc-prev, .rdc-events_close{
  margin-top: 5px;
  font: 400 23px/23px "FontAwesome";
  cursor: pointer;
  transition: .3s all ease;
  color: $brand-primary;

  &:hover {
    color: $white;
  }
}

.rdc-next{
  float: right;
  &:before {
    content: '\e5cc';
    display: inline-block;
    font-family: $material-icons;
    vertical-align: middle;
  }
  @media (min-width: 640px) and (max-width: 991px) {
    margin-right: -20px;
  }
}

.rdc-prev{
  float: left;
  &:before {
    content: '\e5cb';
    display: inline-block;
    font-family: $material-icons;
    vertical-align: middle;
  }
}

.rdc-events_close{
  &:before {
    content: '\f00d';
  }
}

// Events
.rdc-events{
  display: none;
  opacity: 0;
  visibility: hidden;
  transition: .4s all ease-in-out;

  .rd-calendar.show-events & {
    opacity: 1;
    visibility: visible;
  }
}



.rdc-event{
  display: none;

  &.active {
    display: block;
  }
}

// Table
.rdc-table{
  position: relative;
  width: 100%;
  margin-top: 24px;
  table {
    border-spacing: 1px;
    border-collapse: separate;
    margin-left: auto;
    margin-right: auto;
  }

  td {
    position: relative;
  }
}

.rdc-month{
  display: inline-block;
  margin-top: 0;
  color: $white;
}

.rdc-fullyear{
  display: inline-block;
  margin-left: 10px;
  margin-top: 0;
  color: $white;
}

.rdc-table_day{
  width: 35px;
  height: 35px;
  line-height: 32px;
  padding: 3px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: $white;
  background-color: $gray-dark;
  @media (min-width: $screen-sm-min) {
    width: 43px;
    height: 43px;
    line-height: 38px;
  }
}

.rdc-table_date{
  width: 35px;
  height: 35px;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  padding: 3px;
  color: $gray-base;
  text-align: center;
  background-color: $gray-dark;
  box-sizing: border-box;
  @media (min-width: $screen-sm-min) {
    width: 43px;
    height: 43px;
    line-height: 38px;
  }
}

.rdc-table_today{
  background-color: $brand-primary;
  color: $white;
}

.rdc-table_event{
  cursor: pointer;
  transition: .3s all ease;

  &:hover{
    color: #fff;
    background-color: $brand-primary;
  }
}

.rdc-table_next,
.rdc-table_prev{
  opacity: 1;
}