//
// Offsets
// --------------------------------------------------

$medias: (0: null, $screen-xs-min : xs, $screen-sm-min : sm, $screen-md-min : md, $screen-lg-min : lg, $screen-xl-min : xl);

// Insets
// -------------------------

$insets: (0, 10px,25px,30px);

@include indent-responsive(inset, left, padding-left, $medias, $insets);
@include indent-responsive(inset, right, padding-right, $medias, $insets);


// Elements
// -------------------------


// Grid
// -------------------------


// Sections
// -------------------------


// Responsive
// -------------------------

$offsets: (0, 4px, 10px,25px, 30px);

html .page{
  @include indent-responsive(offset, top, margin-top, $medias, $offsets);
}

