/*
* Sidebar Layout
*/
html.rd-navbar-sidebar-linked {
  body {
    padding-left: $navbar-nav-min-width;
  }
}

.rd-navbar-sidebar {
  display: block;

  .rd-navbar-nav {
    li {
      &:hover,
      &.focus {
        > a, > a:hover {
          color: $navbar-nav-hover-color;
          background: $navbar-nav-active-background;
        }
        > .rd-navbar-submenu-toggle {
          color: #fff;
          &:hover {
            cursor: pointer;
            color: #fff;
          }
        }
      }

      .rd-navbar-dropdown,
      .rd-navbar-megamenu {
        transition: opacity 0.3s, height 0.4s ease;
        opacity: 0;
        height: 0;
        overflow: hidden;
      }

      &.opened {
        > .rd-navbar-dropdown,
        > .rd-navbar-megamenu {
          opacity: 1;
          height: auto;
        }
        > a {
          background: $navbar-nav-active-background;
          color: $navbar-nav-hover-color;
        }
        > .rd-navbar-submenu-toggle {
          color: #fff;
          &::after {
            @include transform (rotate(180deg));
          }
        }
      }
    }
  }

  // RD Navbar Toggle
  .rd-navbar-submenu-toggle {
    &::after {
      content: '\f078';
      position: absolute;
      top: 22px;
      right: 0;
      margin-top: -22px;
      width: 65px;
      height: 44px;
      font: 400 14px "FontAwesome";
      line-height: 42px;
      text-align: center;
      transition: 0.4s all ease;
      z-index: 2;
    }
  }

  // RD Navbar Brand
  .rd-navbar-brand {
    text-align: center;
    margin-bottom: $navbar-min-height / 2;
    padding: 10%;

    .brand-name {
      font-size: 30px;
    }
  }

  // RD Navbar Search
  .rd-navbar-search {
    position: relative;
    margin-bottom: 10px;

    &-toggle {
      display: none;
    }

    label {
      display: block;
    }

    &.active {
      .rd-navbar-search-form-input {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  // RD Navbar Live Search Results
  .rd-navbar-live-search-results {
    @include transform(translateY(30px));

    &.active {
      @include transform(translateY(0));
    }

    .search_link {
      p {
        display: none;
      }
    }
  }

  // RD Navbar Collapse
  .rd-navbar-collapse-items {
    top: 0;
    left: 0;
    padding-top: 45px;
    @include transform(scale(.7));
    @include transform-origin(0% 0%);
    opacity: 0;
    visibility: hidden;
  }

  .rd-navbar-collapse {
    position: absolute;
    top: 4px;
    left: 4px;
    display: inline-block;
    z-index: 1;

    &.active {
      .rd-navbar-collapse-items {
        opacity: 1;
        visibility: visible;
        @include transform(scale(1));
      }
    }
  }

  // RD Navbar States
  .rd-navbar-sidebar--visible {
    display: block;
  }

  .rd-navbar-sidebar--hidden {
    display: none;
  }
}