/*
* Fixed Layout
*/

$navbar-fixed-height: $navbar-min-height;
$navbar-fixed-line-height: $navbar-min-line-height;
$navbar-fixed-font-size: $navbar-min-font-size;

$navbar-fixed-panel-color: $navbar-panel-color;
$navbar-fixed-panel-background: $gray-dark;
$navbar-fixed-shadow: 0 0 13px -1px rgba(0, 0, 0, 0.17);

.rd-navbar-fixed {
  display: block;

  // RD Navbar Brand
  .rd-navbar-brand {
    position: relative;
    @include display-flex();
    @include justify-content(center);
    padding-left: ($navbar-fixed-height - $navbar-fixed-line-height)/2;
    width: 100%;
    @media (min-width: $screen-sm-min) {
      width: calc(100% - #{$navbar-fixed-line-height*2 + ($navbar-fixed-height - $navbar-fixed-line-height)/2*2});
    }

    .brand-name {
      display: inline-block;
      //font-size: 20px;
      //line-height: $navbar-fixed-line-height;
      color: $white;
    }

    //.brand-slogan {
    //  display: none;
    //}
  }

  .rd-navbar-brand {
    display: block;
    text-align: left;
    position: absolute;
    top: -100px;
    left: 0;
    right: 112px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    //font-size: 22px;
    //line-height: 46px;
    padding: 5px 0 10px;
    height: auto;
    z-index: 17;

    a {
      display: inline-block;
    }
  }

  // RD Navbar Panel
  .rd-navbar-panel {
    @media (min-width: $screen-sm-min) {
      @include display-flex();
    }
    position: relative;
    left: 0;
    //top: 0;
    right: 0;
    margin-top: 100px;
    padding: ($navbar-fixed-height - $navbar-fixed-line-height) / 2;
    height: $navbar-fixed-height;
    color: $navbar-fixed-panel-color;
    z-index: 999;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      box-shadow: $navbar-fixed-shadow;
      background: $navbar-fixed-panel-background;
      z-index: -1;
    }
  }

  // RD Navbar Toggle
  .rd-navbar-toggle {
    display: inline-block;
    float: left;
  }

  .rd-navbar-nav-wrap {
    @extend %rd-navbar-transition;
    position: absolute;
    top: 220px;
    left: 0;
    width: 232px;
    padding: 10px 0 56px;
    bottom: -56px;
    color: $white;
    background: $navbar-background;
    z-index: 15;
    @include transform(translateX(-120%));

    &.active {
      @include transform(translateX(0));
    }
  }

  .rd-navbar-nav {
    display: block;
    height: 100%;
    overflow: auto;
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    min-height: 400px;

    &:before, &:after {
      content: '';
      display: block;
      height: 8px;
    }

    li {
      > a {
        display: block;
        padding: 11px 56px 11px 16px;
        color: $white;
      }

      &.opened > a, a:hover {
        background: lighten($white, 75%);
      }

      &.active {
        .rd-navbar-submenu-toggle {
          &:after {
            color: $white;
          }
        }

        > a {
          color: $white;
          background: $brand-primary;
        }
      }
    }
  }

  .rd-navbar-dropdown,
  .rd-navbar-megamenu {
    display: none;
  }

  .rd-navbar-submenu {
    position: relative;
    @extend %rd-navbar-transition;

    li > a {
      padding-left: 32px;
    }

    .rd-navbar-dropdown li li > a,
    .rd-navbar-megamenu ul li li > a {
      padding-left: 48px;
    }

    &.opened {
      > .rd-navbar-dropdown,
      > .rd-navbar-megamenu {
        display: block;
      }
    }
  }

  // RD Navbar Search
  .rd-navbar-search {
    display: block;

    //form {
    //  button[type="submit"] {
    //    display: none;
    //  }
    //}

    &.active {
      .form-group {
        opacity: 1;
        visibility: visible;
      }
    }

    &-form, &-toggle {
      right: 4px;
    }
    &-toggle {
      display: block;
      position: fixed;
      top: 4px;
      //right: 56px;
      font-family: 'Material Icons';
      width: 60px;
      height: 60px;
      background-color: $gray-dark;
      z-index: 18;
      color: $color-tulip-tree;
      &:hover {
        color: $navbar-color;
      }
    }

    .form-group {
      position: fixed;
      top: 0;
      padding-top: 4px;
      left: 56px;
      right: 112px;
      height: 56px;
      background: $navbar-background;
      z-index: 17;
      @extend %rd-navbar-transition;
      visibility: hidden;
      opacity: 0;

      input {
        width: 100%;
        height: 46px;
        display: block;
        font-size: 22px;
        line-height: 46px;
      }
    }

    &-form, .rd-navbar-live-search-results {
      position: fixed;
      margin-right: 0;
      margin-left: auto;
    }

    .rd-navbar-live-search-results {
      top: $navbar-fixed-height;
    }

    &-form {
      top: 0;
      z-index: 1000;
      left: 10px;
      right: 0;
      height: 60px;
      //label{
      //  display: none;
      //}

      &-input {
        padding: 0;
        visibility: hidden;
        opacity: 0;
        height: 60px;
        //border: 1px solid #C3C3C3;
        position: relative;
        top: 0;
        background: $gray-dark;
        transition: .3s all ease;
        z-index: 1;
        width: 100%;

        input {
          display: block;
          width: 100%;
          padding: 0 $navbar-fixed-line-height 0 20px;
          height: 60px;
          font-size: 16px;
          line-height: 34px;
          color: $navbar-fixed-panel-color;
          background: darken($navbar-fixed-panel-background, 5%);
        }
      }

      &-submit {
        position: absolute;
        top: ($navbar-fixed-height - $navbar-fixed-line-height) / 2;
        right: 4px;
        opacity: 0;
        visibility: hidden;
        z-index: 9;
        background-color: transparent;
        border: none;
        &:focus {
          outline: none;
        }

      }
    }

    &-toggle {
      &.active {
        //opacity: 0;
        //visibility: hidden;
      }
    }

    &.active {
      .rd-navbar-search-form-input,
      .rd-navbar-search-form-submit {
        opacity: 1;
        visibility: visible;
      }
      .rd-navbar-search-form-submit {
        opacity: 0;
        @media (min-width: $screen-sm-min) {
          opacity: 1;
        }
      }
    }

    @media (min-width: $screen-md-min) {
      &-form, .rd-navbar-live-search-results {
        max-width: 340px;
      }

      .rd-navbar-live-search-results {
        right: 4px;
      }
    }

    @media (min-width: $screen-xs-min) {
      .rd-navbar-brand {
        .brand-name {
          font-size: $navbar-fixed-font-size;
        }
      }
    }
  }

  // RD Navbar Nav
  .rd-navbar-nav {
    li {
      &:hover,
      &.focus {
        > a, > a:hover {
          color: $navbar-nav-hover-color;
          background: $navbar-nav-active-background;
        }
        > .rd-navbar-submenu-toggle {
          color: #fff;
          &:hover {
            cursor: pointer;
            color: #fff;
          }
        }
      }

      .rd-navbar-dropdown,
      .rd-navbar-megamenu {
        transition: opacity 0.3s, height 0.4s ease;
        opacity: 0;
        height: 0;
        overflow: hidden;
      }

      &.opened {
        > .rd-navbar-dropdown,
        > .rd-navbar-megamenu {
          opacity: 1;
          height: auto;
        }
        > a {
          background: $navbar-nav-active-background;
          color: $navbar-nav-hover-color;
        }
        > .rd-navbar-submenu-toggle {
          color: #fff;
          &::after {
            @include transform (rotate(180deg));
          }
        }
      }

    }

    .rd-navbar-submenu-toggle {
      &::after {
        content: '\f078';
        position: absolute;
        top: 22px;
        right: 0;
        margin-top: -22px;
        width: 65px;
        height: 44px;
        font: 400 14px "FontAwesome";
        line-height: 42px;
        text-align: center;
        transition: 0.4s all ease;
        z-index: 2;
        color: $white;
      }
    }
  }

  // Hybrid Styles
  .rd-navbar-collapse,
  .rd-navbar-search-toggle {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    //height: $navbar-fixed-line-height;
    z-index: 1000;

    //background-color: transparent;
    border: none;
    &:focus {
      outline: none;
    }

  }

  // Rd navbar search
  .rd-navbar-shop {
    position: fixed;
    right: 62px;
    top: 28px;
    z-index: 999;
  }

  // RD Navbar States
  &.active {
    .rd-navbar-nav {
      @include transform(translateX(0));
    }
  }

  &.rd-navbar--on-search {
    @media (max-width: 1199px) {
      .brand-name {
        opacity: 0;
        visibility: hidden;
        @include transform(scale(0.7));
      }
    }
  }

  &.rd-navbar--is-clone {
    display: none;

    .rd-navbar-panel {
      @include transform(translateY(-110%));
    }

    &.rd-navbar--is-stuck {
      .rd-navbar-panel {
        @include transform(translateY(0));
      }
    }
  }

  .rd-navbar-fixed--visible {
    display: block;
  }

  .rd-navbar-fixed--hidden {
    display: none;
  }
}