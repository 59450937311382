// Element groups
// --------------------------------------------------

html .element-groups-5 {
  @include element-groups(5px, 5px);
}

html .element-groups-10 {
  @include element-groups(10px, 10px);
}

html .element-groups-15{
  @include element-groups(15px, 15px);
}

html .element-groups-20 {
  @include element-groups(20px, 20px);
}

html .element-groups-30 {
  @include element-groups(30px, 30px);
}



