// Element-groups

@mixin element-groups($offset, $translate-offset) {
  -webkit-transform: translateY(-$translate-offset);
  transform: translateY(-$translate-offset);
  margin-bottom: -$translate-offset;
  margin-left: -$translate-offset;

  > *, > *:first-child {
    display: inline-block;
    margin-top: $offset;
    margin-left: $offset;
  }
}