//
// Icons
// --------------------------------------------------

// Base styles
// --------------------------------------------------

.icon {
  display: inline-block;
  text-align: center;
  transition:.3s;

  &:before {
    position: relative;
    display: inline-block;
    font-weight: 400;
    font-style: normal;
    speak: none;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}


// Alternate icons
// --------------------------------------------------

.icon-default {

}

.icon-mandy {
  color: $color-mandy;
  &:hover{
    color: $white;
  }
}

.icon-primary {
  color: $brand-primary;
  &:hover{
    color: $white;
  }
}

.icon-dark{
  background-color: #141414;
  color: $gray-dark;
}

.icon-white{
  color: $white;
  &:hover{
    color: $brand-primary;
  }
}

// Button Sizes
// --------------------------------------------------

.icon-xs {

}

.icon-sm {
  font-size: 20px; //share icon
  line-height: 1;
  width: 20px;
  height: 20px;
}

.icon-md{
  font-size: 23px;
  line-height: 1.2;
  width: 23px;
  height: 23px;
}
.icon-lg {
  width: 70px;
  height: 70px;
  font-size: 50px;
  line-height: 1.2;
}

.icon-xl{
  width: 115px;
  height: 115px;
  font-size: 90.71px;
  line-height: 1.2;
}