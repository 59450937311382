/**
 * RD Timepicker
 * @Section
 */

$primary: #00796B;

$picker-bg: white;
$picker-shadow: 3px 5px 30px 0px rgba(0, 0, 0, 0.3);
$picker-panel-bg: $primary;
$picker-circle-bg: #eeeeee;
$picker-panel-time-fz: 38px;

.rd-timepicker{
  width: 300px;
  z-index: 10;
  transform: translate(-50%, 30px);
  left: 50%;
  top: 60px;
  position: absolute;
  margin-right: auto;
  background: $picker-bg;
  box-shadow: $picker-shadow;
  visibility: hidden;
  opacity: 0;
  transition: 0.4s all ease-out;
  pointer-events: none;

  &.rd-timepicker--active{
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, 0px);
    pointer-events: all;
  }
  &:before{
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    position: absolute;
    border: 10px solid transparent;
    border-bottom: 10px solid $primary;
  }
  &-panel{
    background: $picker-panel-bg;
    text-align: center;
    position: relative;
    height: 60px;
    padding: 0 50px;
  }
  &-time{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: $picker-panel-time-fz;
    line-height: 60px;
    color: #fff;
    &__hours, &__minutes {
      opacity: 0.7;
      cursor: pointer;
      font-weight: 400;
      color: #fff;
    }
  }
  .rd-timepicker-time--active{
    opacity: 1;
  }
  &-meridiem{
    float: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    &__item{
      display: block;
      line-height: 1.4;
      font-weight: 400;
      font-size: 16px;
      opacity: 0.7;
      cursor: pointer;
      color: #fff;
    }
  }
  &-wrapper{
    width: 275px;
    height: 275px;
    margin: 20px auto;
    border-radius: 50%;
    background: $picker-circle-bg;
    position: relative;
  }
  &-center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background: $primary;
    border-radius: 50%;
  }
  &-line{
    position: absolute;
    background-color: $primary;
    width: 2px;
    height: 125px;
    top: 136.5px;
    left: 136.5px;
    transform-origin: 1px 1px;
    transform: rotate(180deg);
  }
  &-display{
    color: #3d3a3b;
    opacity: 0;
    transform: scale(1.2);
    visibility: hidden;
    transition: .4s ease;
    width: 100%;
    height: 100%;
    transform-origin: center center;
    position: absolute;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &-inner{
      li{
        position: absolute;
        text-align: center;
        border-radius: 50%;
        top: 117.5px;
        left: 117.5px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        overflow: hidden;
        &.rd-timepicker-display__item--active{
          background: $primary;
          color: #fff;
        }
      }
      @for $i from 1 through 12 {
        @if $i > 3 {
          > *:nth-of-type( #{$i} ){
            transform: rotate(#{(($i - 1) * 30) - 60}deg) translate(117.5px) rotate(-#{(($i - 1) * 30) - 60}deg);
          }
        } @else if $i == 1 {
          > *:nth-of-type( #{$i} ){
            transform: rotate(#{(($i - 1) * 30) - 60}deg) translate(117.5px) rotate(#{(($i - 1) * 30) + 60}deg);
          }
        } @else if $i == 2 {
          > *:nth-of-type( #{$i} ){
            transform: rotate(#{(($i - 1) * 30) - 60}deg) translate(117.5px) rotate(#{(($i - 1) * 30)}deg); }
        } @else if $i == 3 {
          > *:nth-of-type( #{$i} ){
            transform: rotate(#{(($i - 1) * 30) - 60}deg) translate(117.5px) rotate(#{(($i - 1) * 30) - 60}deg); }
        }
      }
    }

    &--active{
      opacity: 1;
      transform: scale(1);
      visibility: visible;
    }
  }
}