/*
* Navbar components
*/

// RD Navbar
.rd-navbar {
  display: none;
  background: $navbar-background;
  box-shadow: $navbar-shadow;
}

.rd-navbar-top-panel {
  background-color: $gray;
  width: 100%;
  .rd-navbar-top-panel-wrap {
    position: relative;
    @include display-flex;
    @include justify-content(space-between);
    @include align-items(center);
    padding: 0 15px;
    background-color: $gray;
    height: 60px;
    max-width: 1200px;
    margin: 0 auto;
    p {
      display: none;
      @media (min-width: $screen-sm-min) {
        display: inline-block;
      }
      span {
        color: $white;
      }
    }
    .block-right {
      //position: relative;
      width: 100%;
      height: 60px;
      @media (min-width: $screen-sm-min) {
        width: auto;
        height: auto;
      }
      .list-inline {
        vertical-align: middle;
        display: none;
        margin-right: 20px;
        li.activePage a {
          color: $white;
        }

        li {
          padding: 0 11px;
          a {
            &:focus, &:active {
              outline: none;
            }
          }
        }
        &.active {
          display: block;
          position: absolute;
          top: 50%;
          left:48px;
          transform: translateY(-50%);
        }
        @media (min-width: $screen-sm-min) {
          display: inline-block;
        }

        li + li {
          border-left: 1px solid $gray-darker;
        }
      }
      .rd-navbar-search {
        display: inline-block;
        .rd-navbar-search-form {
          visibility: hidden;
          opacity: 0;
          position: absolute;
          top: 0;
          right: 0;
          width: 320px;
          @media (min-width: 768px) {
            right: 15px;
            width: 284px;
          }
        }
        input {
          background-color: $gray-dark;
          border: none;
          outline: none;
          font-weight: 400;
          font-size: 14px;
          color: $white;
          @include placeholder($white);
          &:focus {
            outline: none;
          }
        }
        &.active {
          .rd-navbar-search-form {
            visibility: visible;
            opacity: 1;
          }
        }
        .rd-navbar-search-toggle {
          vertical-align: middle;
        }
      }
    }
  }

}

.tablet, .mobile {
  .rd-navbar-top-panel {
    position: relative;
    p {
      display: none;
    }
    .block-right {
      .list-inline {
        display: none;
        &.active {
          left: 80px;
          display: block;
        }
      }
    }
  }
}

/*Header Center*/
.header-center {
  .block-banner {
    display: none;
  }
  @media (min-width: $screen-lg-min) {
    .rd-navbar-panel {
      .rd-navbar-brand {
        text-align: center;
        .brand-name span.brand-slogan {
          margin-left: 0;
        }
      }
      @include justify-content(center);
    }
    .rd-navbar-static .rd-navbar-brand {
      display: inline-block;
    }
  }
}

/*Header Fullwidth*/
.header-fullwidth {
  .rd-navbar-fullwidth .rd-navbar-inner{
    padding: 0;
  }
  .rd-navbar-fullwidth .rd-navbar-panel{
    padding: 30px 0 30px 10px;
  }
  @media (min-width: $screen-lg-min) {
    .rd-navbar-static .rd-navbar-panel {
      padding: 30px 0 30px 10px;
    }
    .rd-navbar-top-panel {
      padding: 0 0 0 20px;
    }
  }
  .rd-navbar-static .rd-navbar-inner {
    max-width: 100%;
    padding: 0;
    .block-banner {
      display: none;
    }
  }
  @media (min-width: 1600px) {

    .rd-navbar-static .rd-navbar-panel {
      padding: 15px 0;
      @include clearfix;
      float: left;
      background-color: $gray-dark;
      .rd-navbar-brand {
        padding: 0 20px;
        .brand-name {
          font-size: 30px;
          span.brand-slogan {
            display: inline-block;
          }
        }
      }
    }
    .rd-navbar-static.rd-navbar--is-stuck .rd-navbar-nav-wrap,
    .rd-navbar-static.rd-navbar--is-clone .rd-navbar-nav-wrap,
    .rd-navbar-fullwidth.rd-navbar--is-stuck .rd-navbar-nav-wrap,
    .rd-navbar-fullwidth.rd-navbar--is-clone .rd-navbar-nav-wrap {
      .rd-navbar-nav {
        text-align: left;
        padding: 0 0 0 15px;
      }
    }
    .rd-navbar-nav-wrap {
      .rd-navbar-nav {
        width: 1200px;
        margin: 0 auto;
        text-align: center;
        padding: 0;
        > li:last-of-type {
          position: absolute;
          right: 0;
        }
      }
    }
  }
}

.rd-navbar-panel {
  @media (min-width: $screen-sm-min) {
    @include display-flex;
    @include justify-content(space-between);
    @include align-items(center);

  }

  ul.list-inline-variant-1 {
    //display: inline-block;
    z-index: 2;
    float: right;
    @media (min-width: $screen-lg-min) {
      display: none;
    }
  }
}

// RD Navbar Toggle
.rd-navbar-toggle {
  @include make-toggle(
          $navbar-toggle-preset,
          $navbar-min-line-height,
          $navbar-min-font-size,
          $white
  );
  background-color: transparent;
  border: none;
  &:focus {
    outline: none;
  }
  display: none;
}

// RD Navbar Brand
.rd-navbar-brand {
  .brand-name {
    font-family: $font-family-sec;
    color: $white;
    font-size: 50px;
    line-height: 1;
    font-weight: 700;
    span:nth-child(2) {
      color: $color-ufo-green;
      font-weight: 400;
    }
    span.brand-slogan {
      display: block;
      margin-top: 7px;
      margin-left: 15px;
      font-family: $font-family-base;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.392px;
    }
    span {
      display: inline-block;
    }
  }

  .brand-slogan {
  }
}

.rd-navbar-nav-wrap {
  background-color: $gray-dark;
}

// RD Navbar Nav
.rd-navbar-nav {

  > li:last-of-type {
    float: right;
  }
  ul.list-inline-variant-1 {
    display: none;
    @media (min-width: 1200px) {
      display: block;
      float: right;
    }
  }
}

// Navbar-banner
.block-banner {
  position: relative;
  display: none;
  span {
    position: absolute;
    display: inline-block;
    top: 50%;
    left: 50%;
    @include transform(translateX(-50%) translateY(-50%));
    font-size: 22px;
    font-family: $font-family-sec;
    color: $white;
    text-transform: uppercase;
  }
  @media (min-width: $screen-lg-min) {
    display: inline-block;
  }
}

// RD Navbar Dropdown
.rd-navbar-dropdown {
  display: none;
}

// RD Navbar Search
.rd-navbar-search {
  &-toggle {
    display: inline-block;
    font: 400 20px/60px $material-icons;

    &, &:before, &:after {
      text-align: center;
      width: 60px;
      height: 60px;
    }

    &:before, &:after {
      position: absolute;
      left: 0;
      top: 0;
    }

    &:before {
      content: '\e8b6';
      transition: .3s all ease;
      @include transform(scale(1) rotate(0deg));
    }

    .rd-navbar & {
      &:after {
        content: '\e14c';
        transition: .3s all ease;
        @include transform(scale(0) rotate(-90deg));
      }

      &.active {
        &:before {
          @include transform(scale(0) rotate(90deg));
        }

        &:after {
          @include transform(scale(1) rotate(0deg));
        }
      }
    }

    .rd-navbar-fixed &, .rd-navbar-fixed &:before {
      //width: 48px;
      //line-height: 48px;
    }
  }

  &-form-submit {
    @include make-toggle(
            $navbar-search-toggle-preset,
            $navbar-min-line-height,
            $navbar-min-font-size,
            $navbar-panel-color
    );
    position: absolute;
    top: 53%;
    @include transform(translateY(-50%));
  }

  .sidebar-module & {
    .btn {
      height: 50px;
      &:after {
        display: none;
      }

      @media (min-width: 600px) {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 0;
      }
    }
  }
}

// RD Navbar Live Search Results
.rd-navbar-live-search-results {
  position: absolute;
  left:0;
  right: 0;
  padding: 16px 8px 8px;
  top: 100%;
  font-size: 16px;
  line-height: 34px;
  color: $gray-base;
  background: $gray-dark;
  box-shadow: $navbar-shadow;
  opacity: 0;
  visibility: hidden;
  text-align: left;
  z-index: 998;

  .search-quick-result {
    padding-left: 8px;
    font-size: 14px;
    line-height: 30px;
    color: #757575;
  }

  .search_list {
    margin-top: 4px;
    font-size: 16px;
    line-height: 30px;

    li + li {
      margin-top: 2px;
    }

    .search_list li + li:last-child {
      margin-top: 8px;
      border-top: 1px solid #EBEBEB;
      padding-top: 7px;
    }
  }

  .search_link {
    display: block;
    padding: 3px 8px 8px;
    color: #757575;
    border-radius: 2px;

    &:hover {
      background: $gray-light;
    }

    p {
      margin-top: 0;
      font-size: 14px;
      display: none;
    }
  }

  .search_title {
    color: $gray-base;
    font-weight: 400;
  }

  .search_submit {
    display: block;
    text-align: center;
    padding: 8px;
    font-weight: 700;
    color: $brand-primary;
    text-transform: uppercase;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;

    &:hover {
      background: #F7F7F7;
    }
  }

  &.active {
    visibility: visible;
    opacity: 1;
  }

  @media (min-width: $screen-lg-min) {
    .search_link {
      p {
        display: block;
      }
    }
  }
}

.rd-navbar-live-search-results {
  @include transform(translateY(-100%));

  &.active {
    @include transform(translateY(0));
  }
}

// Rd navbar shop

.rd-navbar-shop {
  display: inline-block;
  font-size: 22px;
  color: $white;
  position: absolute;
  right: 12px;
  @include transform(translateY(-50%));
  top: 50%;
  z-index: 9;
  transition: .3s;
  &:hover {
    color: $brand-primary;
  }
  &:focus {
    outline: 0;
    color: $brand-primary;
  }
}