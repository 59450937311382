//
// Responsive unit
// --------------------------------------------------

$medias: (0: null, $screen-xs-min : xs, $screen-sm-min : sm, $screen-md-min : md, $screen-lg-min : lg, $screen-xl-min : xl);


@include unit-responsive($medias, 20px, 30px);

.unit-spacing-xxs{
  @include unit-spacing($medias, 0px, 10px);
}

.unit-spacing-xs{
  @include unit-spacing($medias, 7px, 15px);
}
.unit-spacing-sm{
  @include unit-spacing($medias, 20px);
}

.unit-spacing-lg{
  @include unit-spacing($medias, 40px);
}






