/**
 * RD Toggles
 * @Section
 */

label{
  cursor: pointer;
  span{
    display: inline-block;
  }
}

.mfToggle__input-outer{
  content: "";
  display: inline-block;
  width: 30px;
  height: 15px;
  background-color: rgba(26, 57, 52, 0.7);
  border-radius: 15px;
  margin-right: 10px;
  transition: background 0.3s ease;
  vertical-align: middle;
  margin-left: 10px;
  &:after{
    content: "";
    display: inline-block;
    width: 20px;
    height: 21px;
    background-color: #F1F1F1;
    border-radius: 20px;
    position: relative;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
    left: -9px;
    top: -4px;
    transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease;
  }
  &:active{
    &:after{
      box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(32, 78, 70, 0.3);
    }
  }
  &.active{
    background-color: rgba(11, 77, 70, 0.76);

    &:after{
      background-color: rgb(74, 177, 159);
      left: 11px;
    }
    &:active{
      &:after{
        box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(27, 105, 74, 0.35);
      }
    }
  }
  input[type="checkbox"]{
    position: absolute !important;
    margin: 0 !important;
    padding: 0 !important;
    top: 0 !important;
    left: 0 !important;
    height: 0 !important;
    width: 0 !important;
    border: none !important;
    overflow: hidden !important;
    opacity: 0 !important;
    z-index: -999999 !important;
  }
}
.mfCheckbox__input-outer{
  position: relative;
  width: 20px;
  height: 20px;
  display: inline-block;
  border: 2px solid;
  margin-left: 10px;
  border-radius: 2px;
  overflow: hidden;
  vertical-align: text-bottom;
  &:before {
    position: absolute;
    content: "";
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    display: block;
    margin-top: -4px;
    margin-left: 6px;
    width: 0;
    height: 0;
    -webkit-animation: checkbox-off 0.3s linear forwards;
    animation: checkbox-off 0.3s linear forwards;
  }
  &:after {
    display: block;
    position: absolute;
    content: "";
    background-color: rgba(0, 0, 0, 0.84);
    left: -5px;
    top: -15px;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    z-index: 1;
    opacity: 0;
    margin: 0;
  }
  &.active{
    &:before {
      box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
      -webkit-animation: checkbox-on 0.3s linear forwards;
      animation: checkbox-on 0.3s linear forwards;
    }
  }
  input[type="checkbox"]{
    position: absolute !important;
    margin: 0 !important;
    padding: 0 !important;
    top: 0 !important;
    left: 0 !important;
    height: 0 !important;
    width: 0 !important;
    border: none !important;
    overflow: hidden !important;
    opacity: 0 !important;
    z-index: -999999 !important;
  }
}
.mfRadio{
  &__input-outer{
    vertical-align: middle;
    position: relative;
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-left: 12px;
    border: 2px solid #333;
    border-radius: 100%;
    transition: 0.1s ease;
    &:after{
      content: '';
      position: absolute;
      top: 3px;
      left: 3px;
      right: 3px;
      bottom: 3px;
      background-color: transparent;
      border-radius: 100%;
      transition: background 0.3s ease;
    }
    &:active{
      box-shadow: 0 1px 3px 1px rgba(220, 220, 220, 0.4), 0 0 0 15px rgba(133, 148, 171, 0.52);
    }
    &:hover{
      &:after{
        background-color: rgba(0,0,0,0.4);
      }
    }

    &.active{
      &:after {
        background-color: #333;
      }
      &:active{
        box-shadow: none;
      }
    }
  }
  input[type="radio"]{
    position: absolute !important;
    margin: 0 !important;
    padding: 0 !important;
    top: 0 !important;
    left: 0 !important;
    height: 0 !important;
    width: 0 !important;
    border: none !important;
    overflow: hidden !important;
    opacity: 0 !important;
    z-index: -999999 !important;
  }
  span{
    vertical-align: middle;
  }
}

@-webkit-keyframes checkbox-on {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px;
  }
  50% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px;
  }
  100% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
  }
}
@keyframes checkbox-on {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px;
  }
  50% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px;
  }
  100% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
  }
}

@-webkit-keyframes checkbox-off {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
  }
  25% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
  }
  50% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-top: -4px;
    margin-left: 6px;
    width: 0;
    height: 0;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px, 0 0 0 0 inset;
  }
  51% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 0,    0 0 0 0,    0 0 0 0,    0 0 0 0,    0 0 0 0,    0 0 0 0,    0px 0px 0 10px inset;
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 0,    0 0 0 0,    0 0 0 0,    0 0 0 0,    0 0 0 0,    0 0 0 0,    0px 0px 0 0px inset;
  }
}
@keyframes checkbox-off {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
  }
  25% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
  }
  50% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-top: -4px;
    margin-left: 6px;
    width: 0;
    height: 0;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px, 0 0 0 0 inset;
  }
  51% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 0,    0 0 0 0,    0 0 0 0,    0 0 0 0,    0 0 0 0,    0 0 0 0,    0px 0px 0 10px inset;
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 0,    0 0 0 0,    0 0 0 0,    0 0 0 0,    0 0 0 0,    0 0 0 0,    0px 0px 0 0px inset;
  }
}
