/**
 * RD Validator
 * @Section
 */

$rd-validation-color: #f00;

.mfValidation {
  position: absolute;
  top: -3px;
  left: 18px;
  color: $rd-validation-color;
  font-weight: inherit;
  font-size: 11px;
  line-height: 2;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: .3s all ease;

  &.mfValidation--active {
    opacity: 1;
    visibility: visible;
  }
}

.mfInput{
  position: relative;
}