//
// Scaffolding Custom
// --------------------------------------------------
a {
  text-decoration: none;
  transition: 0.2s;
  &:active, &:focus {
    text-decoration: none;
    outline: none;
  }
  &:hover {
    text-decoration: none;
  }
}

img {
  width: 100%;
  height: auto;
}

// Footer
//---------------------------

.page-foot {
  background-color: $gray;
  .subscribe {
    position: relative;
    max-width: none;
    input{
      padding:7px 138px 7px 18px ;
    }
    .btn {
      height: 50px;
      @media (min-width: 600px) {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 0;
      }
    }
    @media (min-width: 600px) {
      max-width: 370px;
    }
  }
  * + .copyright {
    margin-top: 20px;
  }
  .copyright {

    padding: 18px 0;
    p {
      display: inline-block;
      margin-left: 10px;
    }
    &.border {
      border-top: 1px solid;
    }
  }
  .rd-navbar-brand {
    display: inline-block;
    .brand-name {
      font-size: 24px;
    }
  }
  .tag {
    color: $gray-base;
    background-color: $gray-dark;
    &:hover {
      background-color: $color-mandy;
      color: $white;
    }
  }
  .footer-variant-3 {
    @include display-flex;
    @include justify-content(space-between);

    .list-inline-variant-1{
      display: none;
      @media (min-width: $screen-sm-min) {
        display: inline-block;
      }
    }
  }
}