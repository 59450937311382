//
// Custom Buttons Styles
// --------------------------------------------------

// Base styles
// --------------------------------------------------

.btn {
  transition: .3s ease-out;
  text-transform: uppercase;
  font-family: $font-family-sec;
  letter-spacing: 0.04em;
  border-radius: 0;

  &:focus,
  &:active,
  &:active:focus {
    outline: none;
  }

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {

    }
  }

  &:hover,
  &:focus,
  &.focus {

  }

  &:active,
  &.active {
    @include box-shadow(none);
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {

  }
  &:after {
    content: '\e5cc';
    display: inline-block;
    font-family: $material-icons;
    margin-left: 10px;
    vertical-align: middle;
    width: 24px;
  }
}

.btn-no-arrow{
  &:after{
    display: none;
  }
}

// Alternate buttons
// --------------------------------------------------

.btn-default {
  @include button-variant-custom($gray-darker, transparent, $gray, $white, $gray-darker, $gray-darker);
}

.btn-primary {
  @include button-variant-custom($white, $brand-primary, $brand-primary, $white, $color-ufo-green,$color-ufo-green);
}

.btn-green {
  @include button-variant-custom($white, $color-ufo-green, $color-ufo-green, $white, $brand-primary, $brand-primary);
}

.btn-mandy {
  @include button-variant-custom($white, $color-mandy, $color-mandy, $color-mandy, $white, $white);
}

.btn-transparent {
  background-color: transparent;
  border: solid rgba($white, .6);
  color: $white;
  &:after{
    margin-left: 0;
  }
  &:hover {
    background-color: $color-mandy;
    border-color: $color-mandy;
  }
}

// Success appears as green
.btn-success {

}

// Info appears as blue-green
.btn-info {

}

// Warning appears as orange
.btn-warning {

}

// Danger and error appear as red
.btn-danger {

}

// Button Sizes
// --------------------------------------------------

.btn-xs {

}

.btn-sm {
  font-size: 14px;
  border-width: 1px;
  padding: 11px 15px 8px;
  vertical-align: middle;
  &:after {
    height: 24px;
    font-size: 23px;
    vertical-align: middle;
    line-height:1;
    margin-left: 11px;
  }
}
.btn-icon-sm {
  font-size: 14px;
  border-width: 1px;
  padding: 12px;
  vertical-align: middle;
  &:after {
    height: 24px;
    font-size: 23px;
    vertical-align: middle;
    line-height:1;
  }
}
.btn-icon-play{
  &:after{
    content:'\e037';
  }
}

.btn-lg {

}

.btn-md {
  padding: 17px 26px 15px;
  font-size: 14px;
  line-height: 1;
  &:after {
    font-size: 33px;
    line-height: 23px;
    height: 24px;
  }
}

// Button Shapes
// --------------------------------------------------

.btn-rect {
  border-radius: 0;
}

// Button Icon styles
// --------------------------------------------------

.btn {

  &.btn-icon {
    padding-left: 17px;
    padding-right: 17px;

    .icon {
      font-size: 28px;
      line-height: 28px;
      vertical-align: middle;
      transition: 0s;
    }

    &-left {
      .icon {
        float: left;
        padding-right: 10px;
      }
    }

    &-right {
      .icon {
        float: right;
        padding-left: 10px;
      }
    }

  }
}