// Blog
//------------------
.blog-post {
  position: relative;
  //overflow: hidden;

  &:before {
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100.5%;
    bottom: 0;
    content: "";
    display: inline-block;
    background: linear-gradient(to top, rgba($gray, 0.9) 23%, transparent 100%);
    transition: background 0s, height 0.4s;
    @media (min-width: $screen-sm-min) {
      height: 50%;
    }
  }

  .tag {
    position: absolute;
    top: 10px;
    left: 10px;
    @media (min-width: $screen-lg-min) {
      top: 20px;
      left: 20px;
    }
  }
  .post-body {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 10px 25px;
    transition: 0.4s;
    z-index: 0;
    width: 100%;
    .h3 {
      @media (max-width: $screen-sm-min - 1) {
        font-size: 20px;
      }
    }
    .h5 {
      @media (max-width: $screen-lg-min - 1) {
        font-size: 15px;
      }
    }
    @media (min-width: $screen-sm-min) {
      padding: 0 30px 25px;

    }
    .post-title {
      @include transform(translateY(10%));
      transition: .4s;
      a:hover {
        color: $brand-primary;
      }
    }
    .post-meta {
      @include transform(translateY(50%));
      width: 100%;
      margin-left: 0;
      margin-top: 10px;
      opacity: 0;
      transition: .4s;
      visibility: hidden;
      p {
        float: left;
      }
    }
    .post-share {
      float: right;
      width: auto;
      .list-share {
        background-color: $gray-lighter;
        transition: 0.5s;
      }
      .icon {
        &:hover {
          color: $white;
          cursor: pointer;
        }
      }
    }
  }
  &:hover {

    .post-meta {
      visibility: visible;
      opacity: 1;
    }
    .post-meta {
      @include transform(translateY(0));
    }
    .post-title {
      @include transform(translateY(0));
    }
    .post-body{
      z-index: 1;
    }
  }
  @media (min-width: $screen-sm-min) {
    &:hover {
      &:before {
        transition: background .5s 0.05s;
        height: 100%;
        background-color: rgba($gray, .8);

      }
    }
  }
  &-variant-2 {
    .btn {
      margin-top: 13px;
      display: none;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0s, background .3s;
    }
    .post-body {
      bottom: 0;
      .post-title {
        @include transform(translateY(100%));
      }
    }

    .post-meta {

    }
    &:hover {
      .post-body {
        .btn {
          transition: opacity 0.3s 0.2s;
        }
        .post-title {
          @include transform(translateY(0));
        }
      }
    }
    .post-meta {
      p {
        span {
          display: none;
        }
      }
    }
    time {
      position: relative;
      margin-left: 7px;
      &:before {
        content: '';
        position: absolute;
        display: inline-block;
        height: 3px;
        width: 3px;
        top: 8px;
        left: -8px;
        background-color: $white;
        border-radius: 50%;
      }
    }
    @media (min-width: $screen-sm-min) {
      .post-meta {
        p {
          span {
            display: inline;
          }
        }
      }
      .btn {
        display: inline-block;
      }
      &:hover {
        .btn {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
  &-variant-3 {
    position: relative;
    overflow: hidden;
    .post-img,
    .post-body {
      display: table-cell;
      width: 50%;
      vertical-align: middle;
    }
    .post-body {

      .post-title {
        display: inline-block;
        width: 40%;
        vertical-align: middle;
        h6 {
          a {
            text-overflow: clip;
            display: inline-block;
          }
        }
        time {
          display: none;
          @media (min-width: $screen-xs-min) {
            display: inline-block;
          }
        }
        h6 + * {
          margin-top: 10px;
        }
      }
      .btn {
        margin-left: 30px;
        opacity: 0;
        transition: transform 1s, background 0.3s;
        @include transform(translateX(100%));
        @media (min-width: $screen-sm-min) {
          margin-left: 45px;
        }
      }
      .post-body-inside {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        @include transform(translateX(38%));
        padding: 10px 21px;
        @include display-flex;
        @include align-items(center);
        transition: .4s;
        h6 {
          font-size: 11px;
          vertical-align: middle;
          display: inline;
        }
        p {
          display: none;
        }
        @media (min-width: $screen-sm-min) {
          h6 {
            font-size: 12px;
          }
          @include transform(translateX(50%));
          p {
            display: inline-block;
          }
        }
        @media (min-width: $screen-lg-min) {
          padding: 25px 32px;
          h6 {
            font-size: 14px;
          }
        }
      }
    }
    &:hover {
      .post-body-inside {
        @include transform(translateX(26%));
      }
      .btn {
        opacity: 1;
        @include transform(translateX(0));
      }
    }
  }
  &-variant-4 {
    position: relative;
    overflow: hidden;
    .post-img,
    .post-body {
      display: table-cell;
      width: 50%;
      vertical-align: middle;
    }
    .post-meta {
      display: inline-block;
    }
    .post-body {

      .post-title {

        display: inline-block;
        width: 70%;
        vertical-align: middle;
      }
      h6 + p {
        margin-top: 8px;
      }
      p + p {
        margin-top: 25px;
      }
      .btn {
        margin-left: 20px;
        opacity: 0;
        transition: transform 1s, background 0.3s;
        @include transform(translateX(100%));
        display: inline-block;
      }
      .post-body-inside {
        @include display-flex;
        @include align-items(center);
        position: absolute;
        padding: 8px 33px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: translateX(30%);
        transition: .4s;

        p {
          display: none;
        }
      }
      @media (min-width: $screen-sm-min) {
        .post-body-inside {
          padding: 25px 33px;
          transform: translateX(25%);
          p, .btn {
            display: inline-block;
          }
        }
        .post-title {
          width: 76%;
        }
      }

    }
    @media (min-width: $screen-lg-min) {
      .post-body-mod-1 {
        .post-body-inside {
          @include transform(translateX(16%));
        }
      }
    }
    &:hover {
      .post-body-inside {
        @include transform(translateX(8%));
        @media (min-width: $screen-xs-min) {
          @include transform(translateX(13%));
        }
      }
      .btn {
        opacity: 1;
        @include transform(translateX(0));
      }
    }

  }
  &-variant-5 {
    .post-img, .post-body {
      position: relative;

      @media (min-width: $screen-sm-min) {
        display: table-cell;
        width: 50%;
        vertical-align: top;
      }
    }
    .post-body {
      padding: 25px 25px 60px;
      @media (min-width: $screen-xs-min) {
        padding: 24px;
      }

      .h5:hover {
        color: $brand-primary;
      }
      .h5 + p {
        margin-top: 10px;
      }
      .post-meta {
        margin-top: 18px;
        @media (min-width: $screen-xs-min) {
          display: inline-block;
        }

        .btn {
          margin-top: 20px;
          @media (min-width: $screen-md-min) {
            margin-top: 63px;
          }

        }
      }
    }
    .tag {
      position: absolute;
      top: 10px;
      left: 10px;
      @media (min-width: $screen-lg-min) {
        top: 20px;
        left: 20px;
      }
    }
    .link {
      position: absolute;
      top: 50%;
      left: 50%;
      @include transform(translateX(-50%) translateY(-50%));
      display: inline-block;
      padding: 17px 27px 17px 50px;
      background-color: rgba($gray, 0.9);
      color: $brand-primary;
      transition: .3s;
      &:before {
        content: '\e250';
        position: absolute;
        display: inline-block;
        font-family: $material-icons;
        font-size: 24px;
        top: 50%;
        left: 13px;
        @include transform(translateY(-50%));
        color: $white;
      }
      &:hover {
        color: $white;
      }

    }
  }
  &-variant-6 {
    .post-body {
      padding: 25px 25px 60px;
      @media (min-width: $screen-xs-min) {
        padding: 25px;
      }
      .h5:hover {
        color: $brand-primary;
      }
      .h5 + p {
        margin-top: 10px;
      }
      .post-title + * {
        margin-top: 20px;
      }
      .tag {
        margin: 0 0 20px 0;
      }
      .post-meta {
        margin-top: 15px;
        @media (min-width: $screen-xs-min) {
          display: inline-block;
        }
        .btn {
          margin-top: 20px;
          @media (min-width: $screen-lg-min) {
            margin-top: 26px;
          }

        }
        a{
          margin-right: 5px;
        }
        time{
          margin-left: 5px;
        }
      }
    }
    .facebook {
      @media (min-width: $screen-md-min) {
        width: 70%;
      }
    }
  }
  &-variant-7 {
    .post-body {
      padding: 25px;
    }

    .post-meta {
      @include display-flex();
      @include justify-content(space-between);
      @include align-items(center);
    }
    .post-share {
      position: relative;
    }
  }
  &-variant-8 {
    &.min-height {
      @media (min-width: $screen-lg-min) {
        min-height: 560px;
      }
    }
    @include display-flex;
    @include flex-direction(column);
    .post-img, .post-body {
      position: relative;
    }
    .post-body {
      padding: 25px;
      .tag {
        position: relative;
        top: 0;
        left: 0;
        margin-left: 0;
      }
      .h5:hover {
        color: $brand-primary;
      }
      .h5 + p {
        margin-top: 10px;
      }
    }
    .tag {
      position: absolute;
      top: 10px;
      left: 10px;
      @media (min-width: $screen-lg-min) {
        top: 20px;
        left: 20px;
      }
    }
    .link {
      position: absolute;
      top: 50%;
      left: 50%;
      @include transform(translateX(-50%) translateY(-50%));
      display: inline-block;
      padding: 17px 27px 17px 50px;
      background-color: rgba($gray, 0.9);
      color: $brand-primary;
      transition: .3s;
      &:before {
        content: '\e250';
        position: absolute;
        display: inline-block;
        font-family: $material-icons;
        font-size: 24px;
        top: 50%;
        left: 13px;
        @include transform(translateY(-50%));
        color: $white;
      }
      &:hover {
        color: $white;
      }
    }
    .post-title + *,
    * + .post-meta {
      margin-top: 10px;
    }
    .post-meta {
      .btn {
        margin-top: 25px;
      }
    }
    .quote-1 {
      padding: 80px 25px 74px 75px;
      margin: -25px -25px 0 -25px;
      &:before {
        left: 14px;
        top: 69px;
      }
    }
    & + .blog-post-variant-8 {
      margin-top: 30px;
    }
    .post-footer {
      position: relative;
      margin-top: auto;
      padding: 0 25px 25px;
      z-index: 9;
    }
  }
  .post-meta {
    time {
      margin-left: 10px;
    }
  }
}

.post-meta {
  position: relative;
  display: inline-block;
  width: 100%;
  color: $white;

  time {
    white-space: nowrap;
  }
  @media (min-width: $screen-sm-min) {

  }
}


/*Post share*/
.post-share {
  text-align: center;
  position: absolute;
  bottom: -5px;
  right: 6px;
  cursor: pointer;
  z-index: 1;
  //visibility: hidden;

  &:hover{
   .list-share{
     opacity: 1;
     visibility: visible;
   }
  }
  .list-share {
    min-width: 50px;
    width: 50px;
    position: absolute;
    padding: 10px;
    top: 31px;
    left: -17px;
    z-index: 2;
    background-color: $gray-lighter;
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;

    &:before {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba($gray-lighter, 0);
      border-bottom-color: $gray-lighter;
      border-width: 10px;
      margin-left: -10px;
    }
    li:first-of-type {
      background-color: $color-azure;
      &:hover {
        background-color: $white;
        a {
          color: $color-azure;
        }
      }
    }
    li:nth-of-type(2) {
      background-color: $color-cerulian;
      &:hover {
        background-color: $white;
        a {
          color: $color-cerulian;
        }
      }
    }
    li:nth-of-type(3) {
      background-color: $color-cinnabar;
      &:hover {
        background-color: $white;
        a {
          color: $color-cinnabar;
        }
      }
    }
    li:nth-of-type(4) {
      background-color: $color-lust;
      &:hover {
        background-color: $white;
        a {
          color: $color-lust;
        }
      }
    }
    a {
      display: inline-block;
      color: $white;
    }
    li, a {
      width: 30px;
      height: 30px;
      padding: 0;
      text-align: center;
      line-height: 2;
    }
  }
}

.blog-posts-width-50 {
  @include clearfix;
  .post-50 {
    @include clearfix;
    width: 100%;
    float: left;
    @media (min-width: 640px) {
      width: 50%;
    }
  }
}

/*Thumb Video*/
.thumb {
  position: relative;
  outline: none;
  &:before {
    content: '\e037';
    position: absolute;
    display: inline-block;
    height: 50px;
    width: 50px;
    font-family: $material-icons;
    font-size: 24px;
    top: 50%;
    left: 50%;
    @include transform(translateX(-50%) translateY(-50%));
    background-color: $gray;
    opacity: 0.9;
    color: $white;
    text-align: center;
    line-height: 50px;
    transition: 0.3s;
  }
  &:active, &:focus {
    outline: none;
    border: none;
  }
  &:hover {
    &:before {
      color: $brand-primary;
    }
  }
}

//.fb-page-responsive {
//  margin-top: 20px;
//}

.wrap-comment {
  .post-meta + p {
    margin-top: 10px;
  }
}

//Sidebar
//----------------------

.sidebar-module {
  @media (min-width: 640px) and (max-width: 1199px) {
    float: left;
    width: 50%;
    padding: 0 15px;

    &:nth-child(odd) {
      clear: left;
    }
    &:first-of-type {
      margin-top: 48px;
    }
  }

  .tag {
    background-color: $gray-dark;
    &:hover {
      background-color: $color-mandy;
    }
  }
  .list-inline-variant-1 {
    li {
      line-height: 2.5;
      a {
        width: 50px;
        height: 50px;
      }
    }
    @media (min-width: $screen-sm-min) {
      li {
        line-height: 3;
        a {
          width: 60px;
          height: 60px;
        }
      }
    }
  }

}
.sidebar{
  &.display-3{
    .sidebar-module:nth-child(n+4){
      display: none;
    }
  }
  &.display-4{
    .sidebar-module:nth-child(n+5){
      display: none;
    }
  }
  &.display-5{
    .sidebar-module:nth-child(n+6){
      display: none;
    }
  }
  &.display-1{
    .sidebar-module:nth-child(n+2){
      display: none;
    }
  }
  &.display-2{
    .sidebar-module:nth-child(n+3){
      display: none;
    }
  }
  &.display-6{
    .sidebar-module:nth-child(n+7){
      display: none;
    }
  }
  &.display-9{
    .sidebar-module:nth-child(n+10){
      display: none;
    }
  }
}


//prev next button
.prev-post,
.next-post {
  position: relative;
  > a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: inline-block;
  }
  h6 {
    transition: .3s;
  }
  &:hover {
    .media-left,
    .media-right {
      span {
        color: $brand-primary;
      }
    }
    .media-body {
      h6 {
        color: $brand-primary;
      }
    }
  }
}