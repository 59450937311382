/*
 * Bootstrap Framework
 */

@import "bootstrap/mixins";

// Reset and dependencies
@import "bootstrap/normalize";
@import "bootstrap/print";

// Core CSS
@import "bootstrap/scaffolding";
@import "bootstrap/type";
@import "bootstrap/code";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";
@import "bootstrap/grid";

// Components
@import "bootstrap/component-animations";
@import "bootstrap/dropdowns";
@import "bootstrap/button-groups";
@import "bootstrap/input-groups";
@import "bootstrap/navs";
@import "bootstrap/navbar";
@import "bootstrap/breadcrumbs";
@import "bootstrap/pagination";
@import "bootstrap/pager";
@import "bootstrap/labels";
@import "bootstrap/badges";
@import "bootstrap/jumbotron";
@import "bootstrap/thumbnails";
@import "bootstrap/alerts";
@import "bootstrap/progress-bars";
@import "bootstrap/media";
@import "bootstrap/list-group";
@import "bootstrap/panels";
@import "bootstrap/responsive-embed";
@import "bootstrap/wells";
@import "bootstrap/close";
@import "bootstrap/glyphicons";

// Components w/ JavaScript
@import "bootstrap/modals";
@import "bootstrap/tooltip";
@import "bootstrap/popovers";
@import "bootstrap/carousel";

// Utility classes
@import "bootstrap/utilities";
@import "bootstrap/responsive-utilities";


/*
* Bootstrap Toolkit
*/

@import "custom/mixins-custom";

// Additional Style Reset
@import "custom/reset";

// Toolkit Core
@import "custom/flex-grid";
@import "custom/text-responsive";
@import "custom/text-alignment";
@import "custom/pull-responsive";
@import "custom/visibility-responsive";

// Toolkit Components
@import "custom/type-custom.scss";
@import "custom/text-styling";
@import "custom/buttons-custom";
@import "custom/icons";
@import "custom/font-awesome";
@import "custom/materialdesignicons";
@import "custom/material-icons";
@import "custom/thumbnails-custom";
@import "custom/unit-responsive";
@import "custom/sections";
@import "custom/offsets";
@import "custom/_element-groups";
@import "custom/scaffolding_custom";
@import "custom/_utilities_custom";
@import "custom/media_custom";
@import "custom/_blog";
@import "custom/_jumbotron-custom";
@import "custom/_pagination_custom";
@import "custom/_breadcrumb-custom";
@import "custom/_instagram-widget";


/*
 * Custom Plugins
 */
@import "plugins/_animate.scss";
@import "plugins/_counter.scss";
@import "plugins/_isotope.scss";
@import "plugins/_magnific-popup.scss";
@import "plugins/_owl-carousel.scss";
@import "plugins/_rd-navbar.scss";
@import "plugins/_rd-parallax.scss";
@import "plugins/_rd-material-tabs.scss";
@import "plugins/_rd-video.scss";
@import "plugins/_rd-calendar.scss";
@import "plugins/_rd-google-map.scss";
@import "plugins/_rd-audio.scss";
@import "plugins/_scroll-to.scss";
@import "plugins/_swiper.scss";
@import "plugins/_ui-to-top.scss";
@import "plugins/_responsive-tabs.scss";
@import "plugins/_progress-bar.scss";
@import "plugins/_timecircles.scss";
@import "plugins/_scrollspy.scss";
@import "plugins/_slick.scss";
@import "plugins/_slick-theme.scss";
@import "plugins/_scrollbar";

// MailForm
@import "plugins/_rd-datepicker.scss";
@import "plugins/_rd-filepicker.scss";
@import "plugins/_rd-mailform.scss";
@import "plugins/_rd-selectmenu.scss";
@import "plugins/_rd-timepicker.scss";
@import "plugins/_rd-toggles.scss";
@import "plugins/_rd-validator.scss";