//
// Custom Thumbnails
// --------------------------------------------------

.thumbnail{
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 0;

  .caption{
    padding: 0;
    color: $gray-base;
  }
}

.img-thumbnail,
.thumbnail{
  box-shadow: none;
}

// Make thumbnails to fit entire container width
.thumbnail-block {
  display: block;

  > img,
  a > img {
    width: 100%;
    height: auto;
  }
}

.img-thumbnail-variant-1 {
  img{width: 100%;}
  @media (min-width: $screen-sm-min) {
    img {  width: auto;  }
  }
}

.img-thumbnail-variant-2{
  img{
    width: 100%;
    height: auto;
  }
}

.img-thumbnail-variant-3{
  position: relative;
  display: inline-block;

  &:before{
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));
    content: '\E8F4';
    font-size: 24px;
    font-family: 'Material Icons';
    font-weight: 400;
    display: inline-block;
    color: $white;
    transition: .3s;
    opacity: 0;
    z-index: 1;
  }
  &:after{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: '';
    background: rgba($gray, .8);
    transition: .3s;
    opacity: 0;
  }
  &:hover{
    &:before,
    &:after {
      opacity: 1;
    }
  }
}