//
// Custom typography
// --------------------------------------------------
body{
  -webkit-font-smoothing: subpixel-antialiased;
}
.page {
  overflow: hidden;
  //padding-top: 56px;
  @media (min-width: $screen-sm-min) {
    padding-top: 0;
  }
}
p{
  margin: 0;
}
// Page header
// -------------------------

.page-header {
}

// Fonts
// -------------------------

.font-default {
  font-family: $font-family-base;
}

// Basic Typography
// -------------------------

#{headings()} {
  margin-top: 0;
  margin-bottom: 0;
  text-transform: uppercase;
  color: $white;
}

h1,
.h1 {
  font-size: 36px;
  line-height: 1.5;

  @media (min-width: $screen-sm-min) {
    line-height: $font-lh-h1;
    font-size: $font-size-h1;
  }
}

h2,
.h2 {
  font-size: 32px;
  line-height: 1.5;

  @media (min-width: $screen-sm-min) {
    line-height: $font-lh-h2;
    font-size: $font-size-h2;
  }
}

h3,
.h3 {
  font-size: 28px;
  line-height: 1.5;

  @media (min-width: $screen-sm-min) {
    line-height: $font-lh-h3;
    font-size: $font-size-h3;
  }
}

h4,
.h4 {
  font-size: 24px;
  line-height: 1.5;
  text-transform: none;
  font-weight: 700;
  @media (min-width: $screen-sm-min) {
    line-height: $font-lh-h4;
    font-size: $font-size-h4;
  }
}

h5,
.h5 {
  font-size: 20px;
  line-height: $font-lh-h5;

  @media (min-width: $screen-sm-min) {

    font-size: $font-size-h5;
  }
}

h6,
.h6 {
    line-height: $font-lh-h6;
    font-size: $font-size-h6;

}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  a {
    color: $white;
    transition: .3s all ease;
  }

  a:hover {
    color: $brand-primary;
  }

  .small {
    color: $gray-dark;
  }
}

p,
.p,
.list {
  a:hover {
    text-decoration: underline;
  }
}

@media (min-width: $screen-xs) {
  .big {
    font-size: 16px;
    line-height: 20px;

    h1 &,
    .h1 & {
      font-size: 142.22%;
      line-height: 1.125;
    }

    h2 &,
    .h2 & {
      font-size: 147.36%;
      line-height: 1.142857142857143;
    }
  }
}

blockquote small,
blockquote .small,
small,
.small {
  font-size: 93.75%;

  h1 &,
  .h1 & {
    font-size: 62.22222222222222%;
  }

  h2 &,
  .h2 & {
    font-size: 63.15789473684211%;
  }

  h3 &,
  .h3 & {
    font-size: 73.33333333333333%;
  }

  h4 &,
  .h4 & {
    font-size: 75%;
  }

  h5 &,
  .h5 & {
    font-size: 72.72727272727273%;
  }

  h6 &,
  .h6 & {
    font-size: 94.11764705882353%;
  }
}

mark {
  background-color: $brand-primary;
  color: $white;
}

.fw-m {
  font-weight: 500;
}

.style-italic {
  font-style: italic;
}

// Lists
// -------------------------

ul,
ol {
  padding: 0;
  margin: 0;
}

.list {
  > li + li {
    margin-top: 10px;
  }
}

.list-variant-1 {
  li {
    padding: 11px 0;
    border-bottom: 1px solid $gray-darker;
    &:last-of-type {
      border-bottom: none;
    }

    a {
      color: $brand-primary;
      &:hover {
        color: $white;
      }
    }
  }
}

// List inline
//
.list-inline {
  //margin-left: -5px;
  //margin-right: -5px;
  //
  //> li {
  //  padding-left: 5px;
  //  padding-right: 5px;
  //}
}

.list-inline-variant-1 {
  display: inline-block;
  li {
    float: left;
    //width: 60px;
    //height: 60px;
    text-align: center;
    line-height: 3;
    font-size: 20px;
    padding: 0;
    transition: .3s;
    a {
      display: block;
      width: 60px;
      height: 60px;
      color: $white;
    }
  }
  li:first-of-type {
    background-color: $color-azure;
    &:hover {
      background-color: $white;
      a {
        color: $color-azure;
      }
    }
  }
  li:nth-of-type(2) {
    background-color: $color-cerulian;
    &:hover {
      background-color: $white;
      a {
        color: $color-cerulian;
      }
    }
  }
  li:nth-of-type(3) {
    background-color: $color-cinnabar;
    &:hover {
      background-color: $white;
      a {
        color: $color-cinnabar;
      }
    }
  }
  li:nth-of-type(4) {
    background-color: $color-lust;
    &:hover {
      background-color: $white;
      a {
        color: $color-lust;
      }
    }
  }
}

.list-inline-sm {
  margin-left: -10px;
  margin-right: -10px;

  > li {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.list-inline-md {
  margin-left: -15px;
  margin-right: -15px;

  > li {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.list-inline-lg {
  margin-left: -30px;
  margin-right: -30px;

  > li {
    padding-left: 30px;
    padding-right: 30px;
  }
}

// List index
//
.list-index {
  @include list-unstyled;
  counter-reset: li;
  li {
    padding: 3px 0;
    &:before {
      content: counter(li, decimal-leading-zero) '.';
      counter-increment: li;
      margin-right: 6px;
      color: $brand-primary;
    }
    a {
      color: $gray-base;
      &:hover {
        color: $brand-primary;
      }
    }
  }
}

// List unordered custom
.list-unordered-variant-1 {
  li {
    padding: 3px 14px;
    position: relative;
    &:before {
      position: absolute;
      left: 0;
      top: 50%;
      @include transform(translateY(-50%));
      content: "";
      display: inline-block;
      width: 4px;
      height: 4px;
      background-color: $brand-primary;
      border-radius: 50%;
    }
    a {
      color: $gray-base;
      &:hover {
        color: $brand-primary;
      }
    }
  }
}

// dl-horizontal
//------------------------

.dl-horizontal-variant-1{
  margin-bottom: 10px;
  dt{
    float:left;
    margin-right: 5px;
    font-weight: 500;
    color: $white;
  }
  dd{
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// Backgrounds
// -------------------------
.bg-crusta {
  background: $color-crusta;
}

.bg-primary {
  background: $brand-primary;
  .quote-1,
  &.quote-1{
    q {
      color: $white;
    }
    &:before {
      color: $white;
    }
  }
}

.bg-green {
  background: $color-ufo-green;
}

.bg-mandy {
  background: $color-mandy;
}

.bg-dark {
  background: $gray-dark;
}

.bg-gray {
  background: $gray;
  &.wrap{
    background: $gray;
  }
}

// Contextual backgrounds
//
.bg-gray-base {
  @include bg-variant-custom(#{$gray-base});
  @extend %context-dark !optional;
}

.bg-gray {
  @include bg-variant-custom(#{$gray});
}

// Blockquote custom
//----------------------------

.quote-1 {
  position: relative;
  padding-left: 40px;
  padding-right: 0;
  border-left: none;
  &:before {
    position: absolute;
    left: 0;
    top: 0;
    content: "\201C";
    display: inline-block;
    font-family: $font-family-sec;
    font-size: 100px;
    line-height: 0.85;
    color: $brand-primary;
  }
  q {
    font-family: $font-family-sec;
    font-size: 20px;
    text-transform: uppercase;
    color: $brand-primary;
    &:before,
    &after {
      display: none;
    }
  }
  a{
    &:hover{
      cite{text-decoration: underline;}
    }
  }
  cite {
    color: $white;
    font-style: normal;
    font-size: 14px;
    &:before {
      content: "\2014";
      display: inline-block;
      margin-right: 5px;
    }
  }
  @media (min-width: $screen-sm-min) {
    padding-left: 60px;
    &:before {
      font-size: 150px;
      line-height: 0.9;
    }
  }
}

.quote-2 {
  margin: 0;
  padding: 20px 30px;
  border:none;
  font-size: 14px;
  color:$white;

  cite{
    font-style: normal;
  }
  q {
    color: $white;
    text-transform: uppercase;
    font-family: $font-family-sec;
    &:before{
      display: none;
    }
  }
  @media (min-width: $screen-sm-min) {

  }
}
/*Audio*/
.audio{
  width: 100%;
  background-color:#171717 ;
  border-radius: 0;
  margin-top: 30px;
}
// Tag custom

.tag-group {
  margin-left: -10px;
  margin-bottom: -10px;
  @include transform(translateY(-10px));
}

.tag {
  display: inline-block;
  padding: 5px 10px 7px;
  margin-left: 10px;
  margin-top: 10px;
  color: $white;
  transition: .3s;
  &:hover {
    color: $white;
  }
  &.bg-crusta {
    &:hover {
      background: $color-mandy;
    }
  }
  &.bg-primary {
    &:hover {
      background: $color-ufo-green;
    }

  }
  &.bg-green {
    &:hover {
      background: $brand-primary;
    }

  }
  &.bg-mandy {
    &:hover {
      background: $white;
      color: $color-mandy;
    }
  }
}

// Colors
// -------------------------

// Contextual colors
//

.page {
  @include text-emphasis-variant('.text-primary', $brand-primary);
  @include text-emphasis-variant('.text-dark', $gray-dark);
  @include text-emphasis-variant('.text-gray', $gray);
  @include text-emphasis-variant('.text-white', $white);
}

/*Navigation*/
.nav-arrows {
  padding-top: 17px;
  .nav-prev,
  .nav-next {
    display: inline-block;
    width: 30px;
    height: 20px;
    line-height: 33px;
    font-size: 33px;
    text-align: center;
    z-index: 10;
    cursor: pointer;
    opacity: 1;

    &:hover {
      opacity: 1;
      &:before {
        color: $white;
      }
    }
    &:before {
      display: inline-block;
      font-family: $material-icons;
      vertical-align: top;
      line-height: 0.6;
    }

    @media (min-width: $screen-sm-min) {
      bottom: 15px;
      padding-right: 26px;
    }
  }
  .nav-prev {
    &:before {
      content: '\e5cb';
    }
  }

  .nav-next {
    border-left: 1px solid;
    &:before {
      content: '\e5cc';
    }
  }
}

