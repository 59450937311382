/* 
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* 
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
  display: none;
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  will-change: transform;
  width: 100%;
}

.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/* 
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}

/* 
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  font: 400 40px/80px 'FontAwesome';
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transition: scale 100ms ease;
  -moz-transition: scale 100ms ease;
  -ms-transition: scale 100ms ease;
  -o-transition: scale 100ms ease;
  transition: scale 100ms ease;
  &:before {
    content: '\f144';
  }
}

.owl-carousel .owl-video-play-icon:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
}

/*
 * Owl Navigation
 */

.owl-nav {

}

.owl-prev, .owl-next {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  @include transform(translateY(-50%));
  font: 400 23px/50px $material-icons;
  line-height: 50px;
  opacity: .9;
  transition: .3s;
  background-color: $gray;
  text-align: center;
  color: $white;

  &:hover {
    color: $brand-primary;
  }
}

.owl-prev {
  left: 0;
  &:before {
    content: '\e5cb';
    //margin-left: -4px;
  }
}

.owl-next {
  right: 0;
  &:before {
    content: '\e5cc';
    //margin-right: -3px;
  }
}

.owl-nav-md {
  .owl-prev, .owl-next {
    display: none !important;
  }
}

@media (min-width: $screen-md-min) {
  .owl-nav-md {
    .owl-prev, .owl-next {
      display: block !important;
    }
  }
}

/*
 * Owl Pagination
 */

.owl-dots {
  text-align: center;
  margin-top: 30px;
}

.owl-dot {
  display: inline-block;
  text-align: center;
  position: relative;
  width: 14px;
  height: 14px;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  background-color: $gray-darker;
  transition: 0.3s ease;

  & + .owl-dot {
    margin-left: 10px;
  }

  &:after {
    content: '';
    position: absolute;
    left: 3px;
    top: 3px;
    bottom: 3px;
    right: 3px;
    background-color: $white;
    border-radius: 50%;
  }

  &.active {
  }
}

.bg-jungle-green {
  .owl-dot {
    &:after {
      background-color: #212121;
    }
  }
}

// Custom owl custom pagination
.owl-custom-pagination {
  margin-top: 53px;
  margin-bottom: 26px;
  .owl-dot-custom {
    display: inline-block;
    width: 43px;
    height: 43px;
    border: 2px solid $white;
    box-shadow: 8px 8px 21px 0 rgba(0, 0, 0, .25);
    font-size: 0;
    cursor: pointer;
    transition: .3s;
    &.active {
      @include transform(scale(1.28));
    }
    & + .owl-dot-custom {
      margin-left: 12px;
    }
  }
}

.owl-carousel[data-dots-custom] {
  .owl-dots {
    display: none !important;
  }
}

@media (min-width: $screen-xs-min) {
  .owl-custom-pagination {
    margin-top: 53px;
    margin-bottom: 26px;
    .owl-dot-custom {
      width: 50px;
      height: 50px;
      & + .owl-dot-custom {
        margin-left: 18px;
      }
    }
  }
}

@media (min-width: $screen-sm-min) {
  .owl-custom-pagination {
    margin-top: 63px;
    margin-bottom: 36px;
    .owl-dot-custom {
      width: 70px;
      height: 70px;
    }
  }
}

// Mobile owl dots

.owl-dots-mobile {
  .owl-dots {
    display: block !important;
  }
}

@media (min-width: $screen-lg-min) {
  .owl-dots-mobile {
    .owl-dots {
      display: none !important;
    }
  }
}